import { createJSONStorage, persist } from 'zustand/middleware'
import { AddressBlockAddress, ViewState } from '@/types'
import { create } from 'zustand'

type AddressStore = {
  address: AddressBlockAddress;
  setAddress: ( _address : AddressBlockAddress ) => void;
  currentView: ViewState;
  setCurrentView: ( _currentView : ViewState, _scrollIntoView?: boolean ) => void;
  isAddressComplete: boolean;
  setIsAddressComplete: ( _isAddressComplete : boolean ) => void;
  verifiedAddress: AddressBlockAddress;
  addressPatientConfirmed: boolean;
  addressWebConfirmed: boolean;
  setVerifiedAddress: ( _verifiedAddress : AddressBlockAddress ) => void;
  userConfirmedAddress: `original` | `verified`;
  setUserConfirmedAddress: ( _userConfirmedAddress : `original` | `verified` ) => void;
  setAddressPatientConfirmed: ( _addressPatientConfirmed : boolean ) => void;
  setAddressWebConfirmed: ( _addressWebConfirmed : boolean ) => void;
  smartyValidationMessage: string;
  setSmartyValidationMessage: ( _smartyValidationMessage : string ) => void;
  reset: () => void;
}

export const useAddressStore = create<AddressStore>()(
  persist( ( set ) => {
    return {
      address: {
        firstName: ``,
        lastName: ``,
        street: ``,
        street2: ``,
        city: ``,
        state: ``,
        zipCode: ``
      },
      smartyValidationMessage: ``,
      currentView: `edit`,
      isAddressComplete: false,
      verifiedAddress: {
        street: ``,
        street2: ``,
        city: ``,
        state: ``,
        zipCode: ``
      },
      userConfirmedAddress: `verified`,
      addressPatientConfirmed: false, // This means the address was verified by the patient but not the address validation API
      addressWebConfirmed: false, // This means the address was verified by the Smarty Address Validation API
      setAddress: ( _address ) => {
        set({
          address: {
            ..._address
          }
        })
      },
      setIsAddressComplete: ( _isAddressComplete ) => set({
        isAddressComplete: _isAddressComplete
      }),
      setCurrentView: ( _currentView, scrollIntoView = true ) => {
        if ( scrollIntoView ) {
          // when changing views scroll to the top of the address block so everything is in view for the user automatically
          // without a need for scrolling
          const addressBlockElement = document.getElementById( `address-block` )
          addressBlockElement?.scrollIntoView({
            behavior: `smooth`,
            block: `start`
          })
        }
        set({
          currentView: _currentView
        })
      },
      setVerifiedAddress: ( _verifiedAddress ) => set({
        verifiedAddress: {
          ..._verifiedAddress
        }
      }),
      setUserConfirmedAddress: ( _userConfirmedAddress ) => set({
        userConfirmedAddress: _userConfirmedAddress
      }),
      setAddressPatientConfirmed: ( _addressPatientConfirmed ) => set({
        addressPatientConfirmed: _addressPatientConfirmed
      }),
      setAddressWebConfirmed: ( _addressWebConfirmed ) => set({
        addressWebConfirmed: _addressWebConfirmed
      }),
      setSmartyValidationMessage: ( _smartyValidationMessage ) => set({
        smartyValidationMessage: _smartyValidationMessage
      }),
      reset: () => {
        set({ address: { street: '', street2: '', city: '', state: '', zipCode: '' } })
        set({ verifiedAddress: { street: '', street2: '', city: '', state: '', zipCode: '' } })
        set({ smartyValidationMessage: '' })
        set({ currentView: 'edit' })
        set({ isAddressComplete: false })
        set({ addressPatientConfirmed: false })
        set({ addressWebConfirmed: false })
        set({ userConfirmedAddress: 'verified' })
      }
    }
  },
  {
    name: `address-store`,
    storage: createJSONStorage( () => sessionStorage )
  })
)


