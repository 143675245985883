'use client';

import { redirect, usePathname, useSearchParams } from 'next/navigation';
function MaintenanceRedirect({
  status
}: {
  status: boolean;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const filteredPathname = pathname === "/maintenance" ? "/" : pathname;
  if (status && pathname !== "/maintenance") return redirect(`/maintenance?from=${encodeURIComponent(`${filteredPathname}${searchParams?.toString() && `?${searchParams.toString()}`}`)}`);
  return null;
}
export default MaintenanceRedirect;