'use client';

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface ProgressStore {
  totalSteps: number | null;
  hideSteps: boolean;
  hideProgress: boolean;
  setHideProgress: (hide: boolean) => void;
  setHideSteps: (hide: boolean) => void;
  setTotalSteps: (steps: number) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  stepUp: () => void;
  stepDown: () => void;
  reset: () => void;
}

export const useProgressStore = create<ProgressStore>()(persist((set, get) => ({
  hideProgress: false,
  hideSteps: false,
  setHideProgress: (hide: boolean) => set({ hideProgress: hide }),
  setHideSteps: (hide: boolean) => set({ hideSteps: hide }),
  totalSteps: null, // starting at null for initial steps calc
  setTotalSteps: (steps: number) => set({ totalSteps: steps }),
  currentStep: 1,
  setCurrentStep: (step: number) => set({ currentStep: step }),
  stepUp: () => {
    const currentStep = get().currentStep;
    if (currentStep !== get().totalSteps) set({ currentStep: currentStep + 1 });
  },
  stepDown: () => {
    const currentStep = get().currentStep;
    if (currentStep !== 1) set({ currentStep: currentStep - 1 });
  },
  reset: () => set({ currentStep: 1, totalSteps: null, hideProgress: false, hideSteps: false }),
}), {
  name: 'progress-storage',
  storage: createJSONStorage(() => sessionStorage),
  skipHydration: true // We do this to avoid rehydrating the store on the server
}));
