import { Events } from './events';

export * from './events';

declare global {
  interface Window {
    dataLayer: Events[];
  }
}

export const trackEvent = (event: Events) => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local') {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push(event);
  }
};
