'use client';

import { ReactNode } from 'react';
import { AppProvider } from '@/context';
import { SdkProvider } from '@/sdk/provider';
import { Maybe, Cart } from '@vue-storefront/magento-types';
import { ZustandStoreRehydrate } from '@/stores/rehydrate';
export function Providers({
  children,
  initialCart
}: {
  children: ReactNode;
  initialCart: Maybe<Cart> | undefined;
}) {
  return <AppProvider initialCart={initialCart} data-sentry-element="AppProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <SdkProvider data-sentry-element="SdkProvider" data-sentry-source-file="providers.tsx">
        {children}
        <ZustandStoreRehydrate data-sentry-element="ZustandStoreRehydrate" data-sentry-source-file="providers.tsx" />
      </SdkProvider>
    </AppProvider>;
}