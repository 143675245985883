import { useEffect } from 'react';
import { useClaimsStore } from './claims';
import { useExampleStore } from './example';
import { useChecklistStore } from './checklist';
import { useDoctorStore } from './doctor';
import { useProgressStore } from './progress';

/* This is an issue with using Zustand stores with persist in Next.js.
 * If you are not using persist, then one shouldn't need to add their store to the rehydrate component.
 * We have to rehydrate the store on the client side to make sure the store is rehydrated after a page refresh, and not on server render
 * https://github.com/pmndrs/zustand/discussions/855
 */
export function ZustandStoreRehydrate() {
  useEffect(() => {
    useExampleStore.persist.rehydrate();
    useClaimsStore.persist.rehydrate();
    useChecklistStore.persist.rehydrate();
    useDoctorStore.persist.rehydrate();
    useProgressStore.persist.rehydrate();
  }, []);
  return null;
}
