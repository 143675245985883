import Link from 'next/link';
import React from 'react';
import { MenuUrologyACSiteCTAClicked, trackEvent } from '@/analytics';
type Props = {
  urlLink: string;
  className?: string;
};
const MenuDrawerCuratedLink = (props: Props) => {
  const {
    urlLink,
    className
  } = props;
  const handleAeroflowLinkClick = () => {
    trackEvent(new MenuUrologyACSiteCTAClicked());
  };
  const url = new URL(urlLink);
  const strippedUrl = url.hostname.replace('www.', '');
  return <div className={`absolute bottom-0 left-0 w-full text-left p-4 ${className}`} data-testid="menu-drawer-curated-test-link" id="menu-drawer-curated-link" data-sentry-component="MenuDrawerCuratedLink" data-sentry-source-file="MenuDrawerCuratedLink.tsx">
      <Link href={url} prefetch={false} className="text-forest underline lowercase" onClick={handleAeroflowLinkClick} data-testid="menuitem-bottom-redirect-link" data-sentry-element="Link" data-sentry-source-file="MenuDrawerCuratedLink.tsx">
        {strippedUrl}
      </Link>
    </div>;
};
export default MenuDrawerCuratedLink;