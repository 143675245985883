import(/* webpackMode: "eager" */ "/home/runner/work/nx-frontends/nx-frontends/apps/urology-resupply/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/nx-frontends/nx-frontends/apps/urology-resupply/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nx-frontends/nx-frontends/apps/urology-resupply/src/components/builder/BuilderInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nx-frontends/nx-frontends/apps/urology-resupply/src/components/global/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nx-frontends/nx-frontends/apps/urology-resupply/src/components/global/MaintenanceListener/MaintenanceRedirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nx-frontends/nx-frontends/apps/urology-resupply/src/components/global/MenuDrawer/MenuDrawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nx-frontends/nx-frontends/apps/urology-resupply/src/components/global/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nx-frontends/nx-frontends/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.0_react-dom@18._2vn5ilua5z2ziryien5kvm5p6y/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nx-frontends/nx-frontends/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.0_react-dom@18._2vn5ilua5z2ziryien5kvm5p6y/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nx-frontends/nx-frontends/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.0_react-dom@18._2vn5ilua5z2ziryien5kvm5p6y/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nx-frontends/nx-frontends/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.0_react-dom@18._2vn5ilua5z2ziryien5kvm5p6y/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Source_Serif_4\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-source-serif-4\"}],\"variableName\":\"sourceSerif\"}");
