'use client';

import Link from 'next/link';
import React from 'react';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
function Logo({
  user
}) {
  const searchParams = useSearchParams();
  const theme = searchParams.get('theme') || 'urology';
  return <Link href="/claim" data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <Image src={theme === 'urology' ? '/uro_logo.svg' : '/diabetes_logo.svg'} alt="Urology Resupply" className={`max-w-[180px] sm:max-w-[200px] md:min-w-[240px] px-2 ${!user && `mx-auto`}`} loading="eager" width={240} height={36} data-testid="header-logo" data-sentry-element="Image" data-sentry-source-file="Logo.tsx" />
    </Link>;
}
export default Logo;