import { SfIconExpandMore } from '@storefront-ui/react';
import AccountUserIcon from '@/components/ui/AccountUserIcon';
import { User } from '@/types';
type UserDropdownMenuProps = {
  open?: () => void;
  isOpen?: boolean;
  user: User;
};
const UserDropdownMenu = (props: UserDropdownMenuProps) => {
  const {
    user,
    open,
    isOpen
  } = props;
  return <div onClick={open} className="flex items-center justify-center gap-1 text-graphite font-sourceSerif4 py-1.5 px-1 rounded-lg hover:transition-transform duration-300 cursor-pointer hover:scale-95" data-testid="test-user-dropdown-menu" title="user-dropdown-menu" data-sentry-component="UserDropdownMenu" data-sentry-source-file="UserDropdown.tsx">
      <AccountUserIcon stroke={'black'} className="w-5 h-5 bg-[#65BAAF] p-1 rounded-full" data-sentry-element="AccountUserIcon" data-sentry-source-file="UserDropdown.tsx" />
      {`${user.firstName[0]}${user.lastName[0]}`}
      <SfIconExpandMore className={`transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} data-sentry-element="SfIconExpandMore" data-sentry-source-file="UserDropdown.tsx" />
    </div>;
};
export default UserDropdownMenu;