const BASE_ANALYTICS_TAG = 'uro_resupply_';

const events = {
  claimsCtaBtnClicked: `${BASE_ANALYTICS_TAG}claims_page_cta_btn_clicked`,
  confirmEditBtnClicked: `${BASE_ANALYTICS_TAG}confirm__address_page_edit_btn_clicked`,
  confirmCTABtnClicked: `${BASE_ANALYTICS_TAG}confirm_address_page_cta_btn_clicked`,
  confirmationPageView: `${BASE_ANALYTICS_TAG}confirmation_page_view`,
  menuAboutCTAClicked: `${BASE_ANALYTICS_TAG}menu_about_cta_clicked`,
  menuInsuranceCTAClicked: `${BASE_ANALYTICS_TAG}menu_insurance_cta_clicked`,
  menuDoctorInfoCTAClicked: `${BASE_ANALYTICS_TAG}menu_doctor_info_cta_clicked`,
  menuShippingAddressCTAClicked: `${BASE_ANALYTICS_TAG}menu_shipping_address_cta_clicked`,
  menuAccountDashboardCTAClicked: `${BASE_ANALYTICS_TAG}menu_account_dashboard_cta_clicked`,
  menuUrologyACSiteCTAClicked: `${BASE_ANALYTICS_TAG}menu_urology_ac_site_cta_clicked`,
  pxLandedOnAboutInfoExpanded: `${BASE_ANALYTICS_TAG}px_landed_on_about_info_expanded`,
  pxLandedOnInsuranceInfoExpanded: `${BASE_ANALYTICS_TAG}px_landed_on_insurance_info_expanded`,
  pxLandedOnDoctorInfoExpanded: `${BASE_ANALYTICS_TAG}px_landed_on_doctor_info_expanded`,
  pxLandedOnShippingAddressInfoExpanded: `${BASE_ANALYTICS_TAG}px_landed_on_shipping_address_info_expanded`,
  pxLandedOnAccountDashboardPage: `${BASE_ANALYTICS_TAG}px_landed_on_account_dashboard_page`,
  pxLandedOnEditShippingAddressPage: `${BASE_ANALYTICS_TAG}px_landed_on_edit_shipping_address_page`,
  pxConfirmedEditedAddress: `${BASE_ANALYTICS_TAG}px_edited_address_click_confirm`,
  pxPresentedCuratedEligibleBlock: `${BASE_ANALYTICS_TAG}px_presented_curated_eligible_block`,
  pxClickedCuratedCTAOnCuratedEligibleBlock: `${BASE_ANALYTICS_TAG}px_clicked_curated_cta_on_curated_eligible_block`,
  pxPresentedLastOrderBlock: `${BASE_ANALYTICS_TAG}px_presented_last_order_block`,
  pxClickedOrderDetailsLinkOnLastOrderBlock: `${BASE_ANALYTICS_TAG}px_clicked_order_details_link_on_last_order_block`,
  pxPresentedReorderSuppliesMessaging: `${BASE_ANALYTICS_TAG}px_presented_reorder_supplies_messaging`,
  pxClickedReorderSuppliesCTA: `${BASE_ANALYTICS_TAG}px_clicked_reorder_supplies_cta`,
  pxPresentedReorderUpcmingMessaging: `${BASE_ANALYTICS_TAG}px_presented_reorder_upcoming_messaging`,
  pxPresentedProblemWithAccountMessagingUnableToOrder: `${BASE_ANALYTICS_TAG}px_presented_problem_with_account_messaging_unable_to_order`,
  pxPresentedProblemWithAccountMessagingAbleToOrder: `${BASE_ANALYTICS_TAG}px_presented_problem_with_account_messaging_able_to_order`,
  pxClickedLearnMoreCTAOnProblemWithAccountTracker: `${BASE_ANALYTICS_TAG}px_clicked_learn_more_cta_on_problem_with_account_tracker`,
  pxClickedProceedWithOrderCTAOnProblemWithAccountTracker: `${BASE_ANALYTICS_TAG}px_clicked_proceed_with_order_cta_on_problem_with_account_tracker`,
  pxPresentedWithOrderPlacedTracker: `${BASE_ANALYTICS_TAG}px_presented_with_order_placed_tracker`,
  pxLandedOnOrderPlacedDetailsPage: `${BASE_ANALYTICS_TAG}px_landed_on_order_placed_details_page`,
  pxPresentedWithPreparingShipmentStatusTracker: `${BASE_ANALYTICS_TAG}px_presented_with_preparing_shipment_status_tracker`,
  pxPresentedWithOrderShippedTracker: `${BASE_ANALYTICS_TAG}px_presented_with_order_shipped_tracker`,
  pxClickedTrackPackagesCTAonOrderShippedTracker: `${BASE_ANALYTICS_TAG}px_clicked_track_packages_cta_on_order_shipped_tracker`,
  pxPresentedWithOrderShippedDetailsPage: `${BASE_ANALYTICS_TAG}px_presented_with_order_shipped_details_page`,
  pxClickedManageDeliveryCTAOnOrderShippedDetailsPage: `${BASE_ANALYTICS_TAG}px_clicked_manage_delivery_cta_on_order_shipped_details_page`,
  pxPresentedWithOrderDeliveredStatusTracker: `${BASE_ANALYTICS_TAG}px_presented_with_order_delivered_status_tracker`,
  pxLandedOnOrderDeliveredDetailsPage: `${BASE_ANALYTICS_TAG}px_landed_on_order_delivered_details_page`,
  pxPresentedTracker: `${BASE_ANALYTICS_TAG}px_presented_tracker`,
  pxClickedMyDashboardOnNoRecentOrderBlock: `${BASE_ANALYTICS_TAG}px_clicked_my_dashboard_on_no_recent_order_block`,
  pxClickedCta: `${BASE_ANALYTICS_TAG}px_clicked_cta`,
  pxPresentedWithEmptyPatientBlockMessage: `${BASE_ANALYTICS_TAG}px-presented_with_empty_patient_block_message`,
} as const;

type Event = (typeof events)[keyof typeof events];

class BaseEvent {
  public readonly event: Event;
  constructor(eventName: Event) {
    this.event = eventName;
  }
}

export class ConfirmationPageView extends BaseEvent {
  constructor() {
    super(events.confirmationPageView);
  }
}

export class ConfirmAddressEditBtnClicked extends BaseEvent {
  constructor() {
    super(events.confirmEditBtnClicked);
  }
}

export class ClaimsPageCtaBtnClicked extends BaseEvent {
  public readonly items: Item[];

  constructor(items: Item[]) {
    super(events.claimsCtaBtnClicked);
    this.items = items;
  }
}

export class ConfirmPageCtaBtnClicked extends BaseEvent {
  constructor() {
    super(events.confirmCTABtnClicked);
  }
}

export class MenuAboutCTAClicked extends BaseEvent {
  constructor() {
    super(events.menuAboutCTAClicked);
  }
}

export class MenuInsuranceCTAClicked extends BaseEvent {
  constructor() {
    super(events.menuInsuranceCTAClicked);
  }
}

export class MenuDoctorInfoCTAClicked extends BaseEvent {
  constructor() {
    super(events.menuDoctorInfoCTAClicked);
  }
}

export class MenuShippingAddressCTAClicked extends BaseEvent {
  constructor() {
    super(events.menuShippingAddressCTAClicked);
  }
}

export class MenuAccountDashboardCTAClicked extends BaseEvent {
  constructor() {
    super(events.menuAccountDashboardCTAClicked);
  }
}

export class MenuUrologyACSiteCTAClicked extends BaseEvent {
  constructor() {
    super(events.menuUrologyACSiteCTAClicked);
  }
}

export class PxLandedOnAboutInfoExpanded extends BaseEvent {
  constructor() {
    super(events.pxLandedOnAboutInfoExpanded);
  }
}

export class PxLandedOnInsuranceInfoExpanded extends BaseEvent {
  constructor() {
    super(events.pxLandedOnInsuranceInfoExpanded);
  }
}

export class PxLandedOnDoctorInfoExpanded extends BaseEvent {
  constructor() {
    super(events.pxLandedOnDoctorInfoExpanded);
  }
}

export class PxLandedOnShippingAddressInfoExpanded extends BaseEvent {
  constructor() {
    super(events.pxLandedOnShippingAddressInfoExpanded);
  }
}

export class PxLandedOnAccountDashboardPage extends BaseEvent {
  constructor() {
    super(events.pxLandedOnAccountDashboardPage);
  }
}

export class PxLandedOnEditShippingAddressPage extends BaseEvent {
  constructor() {
    super(events.pxLandedOnEditShippingAddressPage);
  }
}

export class PxConfirmedEditedAddress extends BaseEvent {
  constructor() {
    super(events.pxConfirmedEditedAddress);
  }
}

export class PxPresentedCuratedEligibleBlock extends BaseEvent {
  constructor() {
    super(events.pxPresentedCuratedEligibleBlock);
  }
}

export class PxClickedCuratedCTAOnCuratedEligibleBlock extends BaseEvent {
  constructor() {
    super(events.pxClickedCuratedCTAOnCuratedEligibleBlock);
  }
}

export class pxPresentedLastOrderBlock extends BaseEvent {
  constructor() {
    super(events.pxPresentedLastOrderBlock);
  }
}

export class PxClickedOrderDetailsLinkOnLastOrderBlock extends BaseEvent {
  constructor() {
    super(events.pxClickedOrderDetailsLinkOnLastOrderBlock);
  }
}

export class PxPresentedReorderSuppliesMessaging extends BaseEvent {
  constructor() {
    super(events.pxPresentedReorderSuppliesMessaging);
  }
}

export class PxClickedReorderSuppliesCTA extends BaseEvent {
  constructor() {
    super(events.pxClickedReorderSuppliesCTA);
  }
}

export class PxPresentedReorderUpcmingMessaging extends BaseEvent {
  constructor() {
    super(events.pxPresentedReorderUpcmingMessaging);
  }
}

export class PxPresentedProblemWithAccountMessagingUnableToOrder extends BaseEvent {
  constructor() {
    super(events.pxPresentedProblemWithAccountMessagingUnableToOrder);
  }
}

export class PxPresentedProblemWithAccountMessagingAbleToOrder extends BaseEvent {
  constructor() {
    super(events.pxPresentedProblemWithAccountMessagingAbleToOrder);
  }
}

export class PxClickedLearnMoreCTAOnProblemWithAccountTracker extends BaseEvent {
  constructor() {
    super(events.pxClickedLearnMoreCTAOnProblemWithAccountTracker);
  }
}

export class PxClickedProceedWithOrderCTAOnProblemWithAccountTracker extends BaseEvent {
  constructor() {
    super(events.pxClickedProceedWithOrderCTAOnProblemWithAccountTracker);
  }
}

export class PxPresentedWithOrderPlacedTracker extends BaseEvent {
  constructor() {
    super(events.pxPresentedWithOrderPlacedTracker);
  }
}

export class PxLandedOnOrderPlacedDetailsPage extends BaseEvent {
  constructor() {
    super(events.pxLandedOnOrderPlacedDetailsPage);
  }
}

export class PxPresentedWithPreparingShipmentStatusTracker extends BaseEvent {
  constructor() {
    super(events.pxPresentedWithPreparingShipmentStatusTracker);
  }
}

export class PxPresentedWithOrderShippedTracker extends BaseEvent {
  constructor() {
    super(events.pxPresentedWithOrderShippedTracker);
  }
}

export class PxClickedTrackPackagesCTAonOrderShippedTracker extends BaseEvent {
  constructor() {
    super(events.pxClickedTrackPackagesCTAonOrderShippedTracker);
  }
}

export class PxPresentedWithOrderShippedDetailsPage extends BaseEvent {
  constructor() {
    super(events.pxPresentedWithOrderShippedDetailsPage);
  }
}

export class PxClickedManageDeliveryCTAOnOrderShippedDetailsPage extends BaseEvent {
  constructor() {
    super(events.pxClickedManageDeliveryCTAOnOrderShippedDetailsPage);
  }
}

export class PxPresentedWithOrderDeliveredStatusTracker extends BaseEvent {
  constructor() {
    super(events.pxPresentedWithOrderDeliveredStatusTracker);
  }
}

export class PxLandedOnOrderDeliveredDetailsPage extends BaseEvent {
  constructor() {
    super(events.pxLandedOnOrderDeliveredDetailsPage);
  }
}

export class pxPresentedTracker extends BaseEvent {
  constructor() {
    super(events.pxPresentedTracker);
  }
}

export class PxClickedMyDashboardOnNoRecentOrderBlock extends BaseEvent {
  constructor() {
    super(events.pxClickedMyDashboardOnNoRecentOrderBlock);
  }
}

export class PxClickedCta extends BaseEvent {
  constructor() {
    super(events.pxClickedCta);
  }
}

export class PxPresentedWithEmptyPatientBlockMessage extends BaseEvent {
  public readonly message: string;

  constructor(message: string) {
    super(events.pxPresentedWithEmptyPatientBlockMessage);
    this.message = message;
  }
}

type Item = { id: string; qty: number; size?: string; description: string };

type ConfirmAddressEditBtnInstance = InstanceType<typeof ConfirmAddressEditBtnClicked>;
type ConfirmationPageViewInstance = InstanceType<typeof ConfirmationPageView>;
type ClaimsPageCtaBtnInstance = InstanceType<typeof ClaimsPageCtaBtnClicked>;
type ConfirmPageCtaBtnInstance = InstanceType<typeof ConfirmPageCtaBtnClicked>;
type MenuAboutCTAClickedInstance = InstanceType<typeof MenuAboutCTAClicked>;
type MenuInsuranceCTAClickedInstance = InstanceType<typeof MenuInsuranceCTAClicked>;
type MenuDoctorInfoCTAClickedInstance = InstanceType<typeof MenuDoctorInfoCTAClicked>;
type MenuShippingAddressCTAClickedInstance = InstanceType<typeof MenuShippingAddressCTAClicked>;
type MenuAccountDashboardCTAClickedInstance = InstanceType<typeof MenuAccountDashboardCTAClicked>;
type MenuUrologyACSiteCTAClickedInstance = InstanceType<typeof MenuUrologyACSiteCTAClicked>;
type PxLandedOnAboutInfoExpandedInstance = InstanceType<typeof PxLandedOnAboutInfoExpanded>;
type PxLandedOnInsuranceInfoExpandedInstance = InstanceType<typeof PxLandedOnInsuranceInfoExpanded>;
type PxLandedOnDoctorInfoExpandedInstance = InstanceType<typeof PxLandedOnDoctorInfoExpanded>;
type PxLandedOnShippingAddressInfoExpandedInstance = InstanceType<typeof PxLandedOnShippingAddressInfoExpanded>;
type PxLandedOnAccountDashboardPageInstance = InstanceType<typeof PxLandedOnAccountDashboardPage>;
type PxLandedOnEditShippingAddressPageInstance = InstanceType<typeof PxLandedOnEditShippingAddressPage>;
type PxConfirmedEditedAddressInstance = InstanceType<typeof PxConfirmedEditedAddress>;
type PxPresentedCuratedEligibleBlockInstance = InstanceType<typeof PxPresentedCuratedEligibleBlock>;
type PxClickedCuratedCTAOnCuratedEligibleBlockInstance = InstanceType<typeof PxClickedCuratedCTAOnCuratedEligibleBlock>;
type pxPresentedLastOrderBlockInstance = InstanceType<typeof pxPresentedLastOrderBlock>;
type PxClickedOrderDetailsLinkOnLastOrderBlockInstance = InstanceType<typeof PxClickedOrderDetailsLinkOnLastOrderBlock>;
type PxPresentedReorderSuppliesMessagingInstance = InstanceType<typeof PxPresentedReorderSuppliesMessaging>;
type PxClickedReorderSuppliesCTAInstance = InstanceType<typeof PxClickedReorderSuppliesCTA>;
type PxPresentedReorderUpcmingMessagingInstance = InstanceType<typeof PxPresentedReorderUpcmingMessaging>;
type PxPresentedProblemWithAccountMessagingUnableToOrderInstance = InstanceType<
  typeof PxPresentedProblemWithAccountMessagingUnableToOrder
>;
type PxPresentedProblemWithAccountMessagingAbleToOrderInstance = InstanceType<
  typeof PxPresentedProblemWithAccountMessagingAbleToOrder
>;
type PxClickedLearnMoreCTAOnProblemWithAccountTrackerInstance = InstanceType<
  typeof PxClickedLearnMoreCTAOnProblemWithAccountTracker
>;
type PxClickedProceedWithOrderCTAOnProblemWithAccountTrackerInstance = InstanceType<
  typeof PxClickedProceedWithOrderCTAOnProblemWithAccountTracker
>;
type PxPresentedWithOrderPlacedTrackerInstance = InstanceType<typeof PxPresentedWithOrderPlacedTracker>;
type PxLandedOnOrderPlacedDetailsPageInstance = InstanceType<typeof PxLandedOnOrderPlacedDetailsPage>;
type PxPresentedWithPreparingShipmentStatusTrackerInstance = InstanceType<
  typeof PxPresentedWithPreparingShipmentStatusTracker
>;
type PxPresentedWithOrderShippedTrackerInstance = InstanceType<typeof PxPresentedWithOrderShippedTracker>;
type PxClickedTrackPackagesCTAonOrderShippedTrackerInstance = InstanceType<
  typeof PxClickedTrackPackagesCTAonOrderShippedTracker
>;
type PxPresentedWithOrderShippedDetailsPageInstance = InstanceType<typeof PxPresentedWithOrderShippedDetailsPage>;
type PxClickedManageDeliveryCTAOnOrderShippedDetailsPageInstance = InstanceType<
  typeof PxClickedManageDeliveryCTAOnOrderShippedDetailsPage
>;
type PxPresentedWithOrderDeliveredStatusTrackerInstance = InstanceType<
  typeof PxPresentedWithOrderDeliveredStatusTracker
>;
type PxLandedOnOrderDeliveredDetailsPageInstance = InstanceType<typeof PxLandedOnOrderDeliveredDetailsPage>;
type pxPresentedTrackerInstance = InstanceType<typeof pxPresentedTracker>;
type PxClickedMyDashboardOnNoRecentOrderBlockInstance = InstanceType<typeof PxClickedMyDashboardOnNoRecentOrderBlock>;
type PxClickedCtaInstance = InstanceType<typeof PxClickedCta>;
type PxPresentedWithEmptyPatientBlockMessageInstance = InstanceType<typeof PxPresentedWithEmptyPatientBlockMessage>;

// Union of all events;
export type Events =
  | ConfirmationPageViewInstance
  | ConfirmAddressEditBtnInstance
  | ClaimsPageCtaBtnInstance
  | ConfirmPageCtaBtnInstance
  | MenuAboutCTAClickedInstance
  | MenuInsuranceCTAClickedInstance
  | MenuDoctorInfoCTAClickedInstance
  | MenuShippingAddressCTAClickedInstance
  | MenuAccountDashboardCTAClickedInstance
  | MenuUrologyACSiteCTAClickedInstance
  | PxLandedOnAboutInfoExpandedInstance
  | PxLandedOnInsuranceInfoExpandedInstance
  | PxLandedOnDoctorInfoExpandedInstance
  | PxLandedOnShippingAddressInfoExpandedInstance
  | PxLandedOnAccountDashboardPageInstance
  | PxLandedOnEditShippingAddressPageInstance
  | PxConfirmedEditedAddressInstance
  | PxPresentedCuratedEligibleBlockInstance
  | PxClickedCuratedCTAOnCuratedEligibleBlockInstance
  | pxPresentedLastOrderBlockInstance
  | PxClickedOrderDetailsLinkOnLastOrderBlockInstance
  | PxPresentedReorderSuppliesMessagingInstance
  | PxClickedReorderSuppliesCTAInstance
  | PxPresentedReorderUpcmingMessagingInstance
  | PxPresentedProblemWithAccountMessagingUnableToOrderInstance
  | PxPresentedProblemWithAccountMessagingAbleToOrderInstance
  | PxClickedLearnMoreCTAOnProblemWithAccountTrackerInstance
  | PxClickedProceedWithOrderCTAOnProblemWithAccountTrackerInstance
  | PxPresentedWithOrderPlacedTrackerInstance
  | PxLandedOnOrderPlacedDetailsPageInstance
  | PxPresentedWithPreparingShipmentStatusTrackerInstance
  | PxPresentedWithOrderShippedTrackerInstance
  | PxClickedTrackPackagesCTAonOrderShippedTrackerInstance
  | PxPresentedWithOrderShippedDetailsPageInstance
  | PxClickedManageDeliveryCTAOnOrderShippedDetailsPageInstance
  | PxPresentedWithOrderDeliveredStatusTrackerInstance
  | PxLandedOnOrderDeliveredDetailsPageInstance
  | pxPresentedTrackerInstance
  | PxClickedMyDashboardOnNoRecentOrderBlockInstance
  | PxClickedCtaInstance
  | PxPresentedWithEmptyPatientBlockMessageInstance;
