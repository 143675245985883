import { AeroflowEndpoints } from '@/types/aeroflowEndpoints';
import { builderModule, BuilderModuleType } from '@vsf-enterprise/builder-sdk';
import { CreateSdkOptions, createSdk } from '@vue-storefront/next';

const options: CreateSdkOptions = {
  middleware: {
    apiUrl: process.env.ALOKAI_SERVER_URL ?? 'http://localhost:4100',
  },
};

export const { getSdk } = createSdk(options, ({ buildModule, middlewareUrl, middlewareModule, getRequestHeaders }) => ({
  magento: buildModule(middlewareModule, {
    apiUrl: middlewareUrl + '/magento',
    ssrApiUrl: middlewareUrl + '/magento',
  }),
  aeroflow: buildModule(middlewareModule<AeroflowEndpoints>, {
    apiUrl: middlewareUrl + '/aeroflow',
    defaultRequestConfig: {
      headers: getRequestHeaders(),
    },
  }),
  cms: buildModule<BuilderModuleType>(builderModule, {
    apiUrl: `${middlewareUrl}/builderio`,
  }),
}));

export const sdk = getSdk();
