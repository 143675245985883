import { useClaimsStore } from '@/stores/claims';
import { useChecklistStore } from '@/stores/checklist';
import { useProgressStore } from '@/stores/progress';
import { useAddressStore } from '@/stores/address';
export const useResetStores = () => {
  const {
    reset: resetClaims
  } = useClaimsStore();
  const {
    reset: resetChecklist
  } = useChecklistStore();
  const {
    reset: resetProgress
  } = useProgressStore();
  const {
    reset: resetAddress
  } = useAddressStore();
  const resetAllStores = () => {
    resetClaims();
    resetChecklist();
    resetProgress();
    resetAddress();
  };
  return {
    resetAllStores
  };
};