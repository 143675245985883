'use client';

import { useState, useRef } from 'react';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';
import UserDropdownMenu from './UserDropdown';
import MenuDrawerUserCard from './MenuDrawerUserCard';
import MenuDrawerLinkBox from './MenuDrawerLinkBox';
import { SfDrawer, SfIconChevronRight, SfIconError } from '@storefront-ui/react';
import MenuDrawerCuratedLink from './MenuDrawerCuratedLink';
import { User } from '@/types';
import Link from 'next/link';
type MenuDrawerProps = {
  deficiency?: string;
  user: User;
};
export default function MenuDrawer({
  user,
  deficiency
}: MenuDrawerProps) {
  const [open, setOpen] = useState(false);
  const nodeRef = useRef(null);
  const drawerRef = useRef(null);
  const toggleDrawer = (state: boolean) => {
    setOpen(state);
  };
  return <>
      <UserDropdownMenu user={user} open={() => toggleDrawer(!open)} isOpen={open} data-sentry-element="UserDropdownMenu" data-sentry-source-file="MenuDrawer.tsx" />

      <Transition ref={nodeRef} in={open} timeout={100} data-sentry-element="Transition" data-sentry-source-file="MenuDrawer.tsx">
        {state => <>
            <SfDrawer ref={drawerRef} open placement={'right'} className={classNames('bg-neutral-50 max-w-[320px] duration-200 transition ease-in-out z-50 mt-[54px]', {
          '-translate-x-0': state === 'entered',
          'translate-x-full': state === 'entering' || state === 'exited'
        })}>
              {/* Display inside the drawer */}
              <div className="w-[320px] py-5 px-5 flex flex-col gap-5 items-stretch justify-end">
                <MenuDrawerUserCard user={user} />
                {deficiency && <Link href={deficiency} className="text-black my-4 flex justify-between items-center" onClick={() => setOpen(false)}>
                    <span className="flex items-center">
                      <SfIconError size="lg" className="text-black-600 mr-2 fill-red-200 text-purple-500" />
                      Issues with your account
                    </span>
                    <SfIconChevronRight />
                  </Link>}
                <MenuDrawerLinkBox user={user} onNavigate={() => setOpen(false)} />
                <MenuDrawerCuratedLink urlLink={'https://aeroflowurology.com'} />
              </div>
            </SfDrawer>
            {/* darkens background  */}
            {state == 'entered' ? <div className="fixed inset-0 bg-black bg-opacity-50 duration-200 transition z-40 mt-[54px]" onClick={() => toggleDrawer(false)} /> : null}
          </>}
      </Transition>
    </>;
}