import { withChildren } from '@builder.io/react';
import { Builder } from '@builder.io/sdk';
import dynamic from 'next/dynamic';

const Container = Builder.registerComponent(withChildren(dynamic(() => import('./Container'))), {
  name: 'container',
  inputs: [],
  canHaveChildren: true,
});

export default Container;
