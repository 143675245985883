"use client"
import { Doctor } from '@/types';
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface DoctorStore {
  doctor: Doctor | null;
  setDoctor: (doctor: Doctor) => void;
}

export const useDoctorStore = create<DoctorStore>()(persist( (set, get) => ({
  doctor: null,
  setDoctor: (doctor: Doctor) => set({ doctor })
}),{
  name: 'doctor-storage',
  storage: createJSONStorage(() => sessionStorage),
  skipHydration: true // We do this to avoid rehydrating the store on the server
}))
