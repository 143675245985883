'use client';

import React, { createContext, useState, useContext } from 'react';
import { type Cart, type Maybe } from '@vue-storefront/magento-types';
type AppContext = {
  cart: Maybe<Cart> | undefined;
  setCart: React.Dispatch<React.SetStateAction<Maybe<Cart> | undefined>>;
};
type Props = {
  children: React.ReactNode;
  initialCart: Maybe<Cart> | undefined;
};
const AppContext = createContext<AppContext>({
  cart: undefined,
  setCart: () => {
    throw new Error('setCart was called before being initialized');
  }
});
export const AppProvider: React.FC<Props> = ({
  children,
  initialCart
}) => {
  const [cart, setCart] = useState<Maybe<Cart> | undefined>(initialCart);
  return <AppContext.Provider value={{
    cart,
    setCart
  }} data-sentry-element="unknown" data-sentry-component="AppProvider" data-sentry-source-file="index.tsx">{children}</AppContext.Provider>;
};
export const useAppContext = () => useContext(AppContext);