import React from 'react';
import { User } from '@/types';
type MenuDrawerUserCardProps = {
  user: User;
};

// At some point there are going to be different Cards for the dependents of the actual patient
const MenuDrawerUserCard = ({
  user
}: MenuDrawerUserCardProps) => {
  if (!user) return null;
  const initials = `${user.firstName[0]}${user.lastName[0]}`;
  const fullName = `${user.firstName} ${user.lastName}`;
  const [year, month, day] = user.dob.split('-');
  const dob = `Date of Birth: ${month}-${day}-${year}`;
  return <section className="bg-[#D8EEEB] outline outline-1 outline-[#B2DCD7] rounded-[4px] p-4 flex flex-row items-center gap-5" data-sentry-component="MenuDrawerUserCard" data-sentry-source-file="MenuDrawerUserCard.tsx">
      <div className="font-serif text-2xl font-extralight">{initials}</div>
      <div className="text-xs font-light">
        <div className="capitalize">{fullName}</div>
        <div>{dob}</div>
      </div>
    </section>;
};
export default MenuDrawerUserCard;