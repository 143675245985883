'use client';

import React from 'react';
import Link from 'next/link';
import { User } from '@/types';
import { logout } from '../../hooks/logout';
import { useResetStores } from '@/components/hooks/useResetStores';
import { MenuAboutCTAClicked, MenuInsuranceCTAClicked, MenuDoctorInfoCTAClicked, MenuShippingAddressCTAClicked, MenuAccountDashboardCTAClicked, trackEvent } from '@/analytics';
import { sanitizeString } from '@/utils/utils';
type MenuDrawerLinkBoxProps = {
  user: User;
  onNavigate: () => void;
};
const MenuDrawerLinkBox = (props: MenuDrawerLinkBoxProps) => {
  const {
    user,
    onNavigate
  } = props;
  const {
    resetAllStores
  } = useResetStores();
  const handleOnClickDashboard = () => {
    trackEvent(new MenuAccountDashboardCTAClicked());
    onNavigate();
  };
  const handleOnClickAbout = () => {
    trackEvent(new MenuAboutCTAClicked());
    onNavigate();
  };
  const handleOnClickInsurance = () => {
    trackEvent(new MenuInsuranceCTAClicked());
    onNavigate();
  };
  const handleOnClickDoctorInfo = () => {
    trackEvent(new MenuDoctorInfoCTAClicked());
    onNavigate();
  };
  const handleOnClickShippingAddress = () => {
    trackEvent(new MenuShippingAddressCTAClicked());
    onNavigate();
  };
  const handleLogout = async () => {
    await logout();
    resetAllStores();
  };
  const menuItems = [{
    label: 'My Dashboard',
    href: '/account',
    clickEvent: handleOnClickDashboard
  }, {
    label: `About ${user.firstName}`,
    href: '/account/about',
    clickEvent: handleOnClickAbout,
    id: 'about'
  }, {
    label: 'Insurance',
    href: '/account/insurance',
    clickEvent: handleOnClickInsurance
  }, {
    label: "Doctor's Info",
    href: '/account/doctor-info',
    clickEvent: handleOnClickDoctorInfo
  }, {
    label: 'Shipping Address',
    href: '/account/shipping-address',
    clickEvent: handleOnClickShippingAddress
  }, {
    label: 'Logout',
    action: handleLogout
  }];
  return <section className="flex flex-col gap-3 text-[16px]" data-sentry-component="MenuDrawerLinkBox" data-sentry-source-file="MenuDrawerLinkBox.tsx">
      {menuItems.map((item, index) => <div key={index}>
          {item.action ? <button onClick={item.action} className="text-black text-base capitalize cursor-pointer hover:text-gray-600" data-testid={item.action === handleLogout ? 'logout-button' : undefined}>
              {item.label}
            </button> : <Link href={item.href} className="text-black text-base capitalize hover:text-gray-600" onClick={item.clickEvent} data-testid={sanitizeString(`menuitem-${item.id ? item.id : item.label}`)}>
              {item.label}
            </Link>}
        </div>)}
    </section>;
};
export default MenuDrawerLinkBox;