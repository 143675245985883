'use client';

import { useSearchParams } from 'next/navigation';
export const getThemedContacts = searchParams => {
  const theme = searchParams.get('theme') || 'urology';
  if (theme === 'urology') return {
    email: 'info@aeroflowurology.com',
    phone: '844-276-5588',
    fax: '800-831-2454'
  };
  return {
    email: 'info@aeroflowdiabetes.com',
    phone: '866-456-6755',
    fax: '866-609-1733'
  };
};
function ThemeProvider({
  children
}) {
  const searchParams = useSearchParams();
  const theme = searchParams.get('theme') || 'urology';
  return <div className="h-screen" data-theme={theme} data-sentry-component="ThemeProvider" data-sentry-source-file="ThemeProvider.tsx">
      {children}
    </div>;
}
export default ThemeProvider;